import "../styles/helpers/resetNew.scss"
import "../styles/helpers/animations.scss"
//import "../styles/helpers/back-lights.scss"
import "../styles/helpers/moving-objects.scss"

import "../styles/ui/index.scss"

import "../styles/main-page/index.scss"

import * as amplitude from "@amplitude/analytics-browser"
import mainconfig from "../config"

/* -------------------------------- AMPLITUDE ------------------------------- */
amplitude.init(mainconfig.services.amplitude.key, {
	defaultTracking: true,
})

const button1 = document.getElementById("button-1")
const button2 = document.getElementById("button-2")
const button3 = document.getElementById("button-3")

button1.addEventListener("click", function () {
	amplitude.track("bot_button_click", {
		buttonText: "Telegram Bot",
	})
})

button2.addEventListener("click", function () {
	amplitude.track("bot_button_click", {
		buttonText: "Start the Magic!",
	})
})
button3.addEventListener("click", function () {
	amplitude.track("bot_button_click", {
		buttonText: "Claim Your Free Masterpiece",
	})
})

/* ------------------------------- ANIMATIONS ------------------------------- */

const scrollElements = document.querySelectorAll(".reveal")

let options = {
	threshold: 0, //0.1
}
const observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			entry.target.classList.add("animated")
			observer.unobserve(entry.target)
		}
	})
}, options)

document.addEventListener("DOMContentLoaded", function () {
	scrollElements.forEach((el) => observer.observe(el))
	amplitude.track("BITCOIN.TOON.ORG_pageLoad")
})

/* ------------------------------ CLICK ON LOGO ----------------------------- */
const logo = document.getElementById("logo")

logo.addEventListener("click", function () {
	window.scrollTo({ top: 0, behavior: "smooth" })
})
