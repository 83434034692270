import { queryStringToObject } from "./tools/url"

const query = queryStringToObject(window.location.search.substring(1))
const isDebug = !!query["debug"]
const isProduction = true
const clientToken = window.localStorage["tooncoin:client_token"] || makeClientToken()

window.localStorage["tooncoin:client_token"] = clientToken
window.localStorage["tooncoin:first_visit_at"] = window.localStorage["tooncoin:first_visit_at"] || Date.now()

const mainconfig = {
	query: query,
	isDebug: isDebug,
	isProduction: isProduction,
	isMobile: /iphone|ipad|android/i.test(window.navigator.userAgent),
	clientToken: clientToken,
	locale: query["locale"] || query["lang"] || window.navigator.language || undefined,
	services: {
		amplitude: {
			oldkey: "d12f46bfe20777d8e7ad7481e1446b46",
			key: "7bbcfa6ea002faf6bc3f4bfc2fc43c37",
		},
	},
}

export default mainconfig

function makeClientToken() {
	let platformSegment = "u"
	const userAgent = navigator.userAgent || navigator.vendor || window.opera
	if (/android/i.test(userAgent)) {
		platformSegment = "a"
	} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		platformSegment = "i"
	}

	let result = ""
	const map = "abcdef0123456789"
	for (let i = 0; i < 32; i++) {
		result += map.charAt(Math.floor(Math.random() * map.length))
	}

	return ["b", platformSegment, result].join(":")
}
